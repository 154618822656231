import {
  CalderaDigitalThemeConsumer,
  CalderaDigitalThemeProvider,
  GlobalStyle,
} from '@caldera-digital/theme'
import React from 'react'
import { ThemeProvider } from 'styled-components'

import { theme as customTheme } from './src/style/theme'

import 'react-image-gallery/styles/css/image-gallery.css'

export const wrapRootElement = ({ element }) => (
  <CalderaDigitalThemeProvider theme={customTheme}>
    <CalderaDigitalThemeConsumer>
      {theme => (
        <ThemeProvider theme={theme}>
          <>
            <GlobalStyle />
            {element}
          </>
        </ThemeProvider>
      )}
    </CalderaDigitalThemeConsumer>
  </CalderaDigitalThemeProvider>
)
