export const theme = {
  maxContainerWidth: '1180px',
  defaultFont: '"Antic", sans-serif',
  defaultHeaderFont: '"Antic", sans-serif',
  fancyFont: '"Zeyada", sans-serif',
  postFont: '"Lato", sans-serif',
  defaultFontSize: '16px',
  defaultParagraphLineHeight: '1.4',
  defaultFontColor: '#1B1A1A',
  defaultHeaderColor: '#1B1A1A',
  defaultMobileFontSize: '16px',
  defaultTransition: 'all 0.2s linear',
  disabledColor: '#888888',
  errorColor: 'rgba(224,32,32,0.75)',
  warnColor: 'rgba(237,120,25,0.75)',
  focusColor: '#8E5A3C',
  grayText: '#707070',
  lightGray: '#ECECEC',
  linkColor: '#8E5A3C',
  mobileHeaderHeight: '60px',
  desktopHeaderHeight: '70px',
  primaryColor: '#E2B39F',
  secondaryColor: '#8E5A3C',
  // hoveredPrimaryColor: '#5cbfad',
  // pressedPrimaryColor: '#51b5a3',
  // black: '#000000',
  // secondaryColor: '#65C9E2',
  // lightBackgroundColor: 'rgba(70,70,70,0.03)',
  // babyBlue: '#6E95CB',
  // white: '#ffffff',
  // magenta: '#E26C95',
  // orange: '#FDA373',
  // pink: '#DE9BCC',
  // charcoal: '#464646',
  bodyBackgroundColor: '#ffffff !important',
}
